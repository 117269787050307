import { createApi } from '@reduxjs/toolkit/query/react';
import { NFT_CONTRACT_HASH } from 'utils/configs';
import { addressToScriptHash, furaNftToNft } from 'utils/convertors';
import { Nft } from 'utils/models';
import { furaBaseQuery } from 'utils/queries';

export const furaApi = createApi({
  reducerPath: 'furaApi',
  baseQuery: furaBaseQuery(),
  tagTypes: ['Nft'],
  endpoints: builder => ({
    getNfts: builder.query<Nft[], { address: string }>({
      query: ({ address }) => ({
        method: 'GetAssetsHeldByContractHashAddress',
        params: {
          Address: addressToScriptHash(address),
          ContractHash: NFT_CONTRACT_HASH,
        },
      }),
      transformResponse: (result: any) => result.result.map(furaNftToNft),
      providesTags: ['Nft'],
    }),
  }),
});

export const { useGetNftsQuery } = furaApi;
