import { useBreakpoint } from '@liuqiang1357/react-breakpoints';
import { Collapse } from 'antd';
import { ComponentProps, FC, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { useMatch } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import chevronDown from 'assets/images/shared/chevron-down.svg';
import close from 'assets/images/shared/close.svg';
import lightning from 'assets/images/shared/lightning.svg';
import menu from 'assets/images/shared/menu.svg';
import neoLogo from 'assets/images/shared/neo-logo.svg';
import star from 'assets/images/shared/star.svg';
import { Button } from 'components/base/Button';
import { Link } from 'components/base/Link';
import { BREAKPOINTS } from 'utils/misc';
import { Container } from './Container';
import { Mediums } from './Mediums';
import { Wallets } from './Wallets';

export const Header: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const breakpoint = useBreakpoint(BREAKPOINTS);

  const [expanded, setExpanded] = useState(false);

  const indexMatched = useMatch('/');
  const nftsMatched = useMatch('/nfts');

  return (
    <RemoveScroll enabled={expanded}>
      <div
        className={twMerge(
          `relative z-10 -mb-[var(--header-height)] overflow-auto ${
            expanded ? 'fixed inset-0 z-10 mb-0 flex flex-col justify-between bg-[#010E29]' : ''
          }`,
          className,
        )}
        onClick={event => {
          const target = event.target;

          if (target instanceof HTMLElement) {
            let element = target;
            for (;;) {
              if (element instanceof HTMLAnchorElement) {
                setExpanded(false);
                break;
              }
              if (element.parentElement != undefined) {
                element = element.parentElement;
              } else {
                break;
              }
            }
          }
        }}
      >
        <div>
          <div className="flex h-[var(--header-height)] items-center" {...props}>
            <Container className="flex items-center justify-between">
              <div className="flex items-center">
                <Link type="image" href="https://neo.org">
                  <img src={neoLogo} />
                </Link>

                <div className="ml-[48px] hidden w-px self-stretch bg-[#353945] sm:block" />
                <Link
                  className="ml-[56px] hidden sm:inline"
                  type="text"
                  to="/"
                  color={indexMatched ? 'white' : 'gray'}
                >
                  Tour
                </Link>
                <Link
                  className="ml-[48px] hidden sm:inline"
                  type="text"
                  to="/nfts"
                  color={nftsMatched ? 'white' : 'gray'}
                >
                  NFTs
                </Link>
              </div>

              <div className="hidden items-center sm:flex">
                <div className="flex items-center space-x-[24px]">
                  <Mediums />
                </div>

                {breakpoint !== 'base' && <Wallets className="ml-[80px]" />}
              </div>

              <Button className="sm:hidden" type="image" onClick={() => setExpanded(!expanded)}>
                <img src={expanded ? close : menu} />
              </Button>
            </Container>
          </div>

          {expanded && (
            <Container className="flex flex-col items-start space-y-[24px]">
              <Link className="text-[24px] font-medium" type="text" size="sm" href="/#home">
                Home
              </Link>
              <Link className="text-[24px] font-medium" type="text" size="sm" href="/#tour-map">
                Tour Map
              </Link>
              <Link className="text-[24px] font-medium" type="text" size="sm" href="/#partners">
                Guests
              </Link>

              <Collapse
                className="self-stretch"
                ghost
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <div>
                    <img className={`${isActive === true ? 'rotate-180' : ''}`} src={chevronDown} />
                  </div>
                )}
                defaultActiveKey={'nfts'}
              >
                <Collapse.Panel
                  key="nfts"
                  header={<div className="text-[24px] font-medium">NFTs</div>}
                >
                  <div className="mt-[24px] flex flex-col items-start">
                    <Link className="flex" type="text" to="/nfts?tab=NftGallery">
                      <img src={lightning} />
                      <div className="ml-[10px]">NFT Gallery</div>
                    </Link>
                    <Link className="mt-[24px] flex" type="text" to="/nfts?tab=MyCollection">
                      <img src={star} />
                      <div className="ml-[10px]">My Collection</div>
                    </Link>
                  </div>
                </Collapse.Panel>
              </Collapse>

              <Collapse
                className="self-stretch"
                ghost
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <div>
                    <img className={`${isActive === true ? 'rotate-180' : ''}`} src={chevronDown} />
                  </div>
                )}
              >
                <Collapse.Panel
                  key="links"
                  header={<div className="text-[24px] font-medium">Links</div>}
                >
                  <div className="mt-[24px] flex space-x-[24px]">
                    <Mediums />
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Container>
          )}
        </div>

        {breakpoint === 'base' && expanded && (
          <Wallets className="my-[48px] shrink-0 self-center" />
        )}
      </div>
    </RemoveScroll>
  );
};
