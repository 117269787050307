import useUrlState from '@liuqiang1357/use-url-state';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ComponentProps, FC, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import inactiveNft from 'assets/images/common/inactive-nft.svg';
import arrowLeft from 'assets/images/nfts/arrow-left.svg';
import arrowRight from 'assets/images/nfts/arrow-right.svg';
import calendar from 'assets/images/nfts/calendar.svg';
import candleSticks from 'assets/images/nfts/candle-sticks.svg';
import share from 'assets/images/nfts/share.svg';
import { Button } from 'components/base/Button';
import { Link } from 'components/base/Link';
import { Container } from 'components/shared/Container';
import { useMintMutation } from 'store/apis/invoke';
import { useGetMintedCountQuery, useGetMintedQuery } from 'store/apis/invokeRead';
import { useConnectedWalletState } from 'store/slices/walletStates';
import { NFT_CATEGORIES, NFT_MAX_COUNT, STATIONS } from 'utils/configs';
import { formatNumber } from 'utils/formatters';
import { CURRENT_STATION_INDEX } from 'utils/misc';
import { NftCategory } from 'utils/models';

export const NftGallery: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();

  const [urlState, setUrlState] = useUrlState({
    category: undefined,
    secret: undefined,
  });

  const currentNftCategory =
    NFT_CATEGORIES.find(nftCategory => nftCategory.name === urlState.category) ?? NFT_CATEGORIES[0];

  const currentIndex = NFT_CATEGORIES.indexOf(currentNftCategory);

  const currentStation = STATIONS.find(station =>
    station.nftCategories.includes(currentNftCategory.name),
  );

  const currentStationIndex = currentStation && STATIONS.indexOf(currentStation);

  const { currentData: mintedCount } = useGetMintedCountQuery({
    nftCategoryName: currentNftCategory.name,
  });

  const leftCount = mintedCount != undefined ? NFT_MAX_COUNT - mintedCount : undefined;

  const walletState = useConnectedWalletState();

  const { currentData: minted } = useGetMintedQuery(
    walletState && urlState.secret != undefined
      ? {
          address: walletState.address,
          secret: urlState.secret,
        }
      : skipToken,
  );

  const [mintMutation, { isLoading }] = useMintMutation();

  const mint = async () => {
    if (!walletState) {
      return;
    }
    await mintMutation({ address: walletState.address, secret: urlState.secret }).unwrap();
  };

  const renderNftCategory = (nftCategory: NftCategory, index: number) => {
    const station = STATIONS.find(station => station.nftCategories.includes(nftCategory.name));

    const stationIndex = station && STATIONS.indexOf(station);

    return (
      <div
        className="flex cursor-pointer flex-col items-center"
        onClick={() => setUrlState({ category: NFT_CATEGORIES[index].name, secret: undefined })}
      >
        {stationIndex != undefined && (
          <img
            className={`h-[256px] w-[256px] overflow-hidden rounded-[8px] object-cover transition-all duration-300 ${
              currentIndex === index ? '' : 'origin-bottom scale-[0.9]'
            }`}
            src={stationIndex <= CURRENT_STATION_INDEX ? nftCategory.image : inactiveNft}
          />
        )}
        <div className="mt-[24px] font-semibold">{nftCategory.name}</div>
        <div className="mt-[8px] text-[14px] text-[#777E90]">{station?.date}</div>
      </div>
    );
  };

  return (
    <div className={twMerge('flex flex-col', className)} {...props}>
      <Container className="flex flex-col items-center sm:flex-row">
        <div className="sm:hidden">
          <div className="text-[32px] font-semibold">{currentNftCategory.name}</div>
          <div className="mt-[20px]">
            Standing at the door that connects Web2 and Web3 world, Neo, as one of the most
            established blockchain in the space, will visit renowned universities in the US and
            create a series of NFTs that combine landmarks of each location and the color of each
            campus.
          </div>
        </div>

        <div className="mt-[32px] rounded-[10px] bg-gradient-to-b from-white to-white/0 p-[5px] sm:mt-0">
          {currentStationIndex != undefined && (
            <img
              className="w-full rounded-[5px] sm:h-[558px] sm:w-[441px]"
              src={
                currentStationIndex <= CURRENT_STATION_INDEX
                  ? currentNftCategory.image
                  : inactiveNft
              }
            />
          )}
        </div>

        <div className="mt-0 sm:ml-[93px]">
          <div className="hidden sm:block">
            <div className="text-[32px] font-semibold">{currentNftCategory.name}</div>
            <div className="mt-[20px]">
              Standing at the door that connects Web2 and Web3 world, Neo, as one of the most
              established blockchain in the space, will visit renowned universities in the US and
              create a series of NFTs that combine landmarks of each location and the color of each
              campus.
            </div>
          </div>

          <div className="mt-[32px] grid w-fit grid-cols-[repeat(3,auto)] gap-y-[20px]">
            <img src={calendar} />
            <div className="ml-[12px] text-[18px] font-medium text-[#777E90]">Date</div>
            <div className="ml-[40px] text-[18px] font-medium">{currentStation?.date}</div>

            <img src={candleSticks} />
            <div className="ml-[12px] text-[18px] font-medium text-[#777E90]">Mint</div>
            <div className="ml-[40px] text-[18px] font-medium">
              {formatNumber(leftCount)}/{formatNumber(NFT_MAX_COUNT)} left
            </div>
          </div>

          <div className="mt-[32px] h-px bg-[#353945]" />

          <Link
            className="mt-[32px] font-bold"
            type="text"
            href="https://neomarketing.notion.site/NFT-Claim-Instruction-1e5eda82fa4a48bd9366be0aac89356c"
          >
            <img src={share} />
            <div className="ml-[10px]">INSTRUCTION OF CONNECT WALLET AND CLAIM NFT</div>
          </Link>

          <Button
            className="mt-[32px] w-full sm:w-[240px]"
            type="filled"
            size="lg"
            color="blue-green"
            disabled={
              !walletState ||
              urlState.secret == undefined ||
              minted !== false ||
              leftCount == undefined ||
              leftCount <= 0
            }
            loading={isLoading}
            onClick={mint}
          >
            Claim
          </Button>
        </div>
      </Container>
      <Container className="mt-[56px] flex justify-start sm:mt-[80px] sm:justify-center">
        <Button
          type="image"
          disabled={currentIndex <= 0}
          onClick={() => {
            setUrlState({ category: NFT_CATEGORIES[currentIndex - 1].name, secret: undefined });
            swiper?.slideTo(currentIndex - 1);
          }}
        >
          <img src={arrowLeft} />
        </Button>
        <Button
          className="ml-[20px]"
          type="image"
          disabled={currentIndex >= NFT_CATEGORIES.length - 1}
          onClick={() => {
            setUrlState({ category: NFT_CATEGORIES[currentIndex + 1].name, secret: undefined });
            swiper?.slideTo(currentIndex + 1);
          }}
        >
          <img src={arrowRight} />
        </Button>
      </Container>

      <div className="mt-[56px] overflow-hidden sm:mt-[80px]">
        <Container>
          <Swiper
            className="max-w-fit overflow-visible"
            spaceBetween={32}
            slidesPerView="auto"
            slideToClickedSlide
            initialSlide={currentIndex}
            onSwiper={setSwiper}
          >
            {NFT_CATEGORIES.map((nftCategory, index) => (
              <SwiperSlide key={nftCategory.name} className="w-fit">
                {() => renderNftCategory(nftCategory, index)}
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>
    </div>
  );
};
