import { Input } from 'antd';
import { message } from 'antd';
import { ComponentProps, FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import arrowRight from 'assets/images/shared/arrow-right.svg';
import neo from 'assets/images/shared/neo.svg';
import { Button } from 'components/base/Button';
import { Link } from 'components/base/Link';
import { useSubscribeMutation } from 'store/apis/subscribe';
import { BackendError } from 'utils/errors';
import { Container } from './Container';
import { Mediums } from './Mediums';

const EMAIL_PATTERN = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

export const Footer: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const [focused, setFocused] = useState(false);
  const [email, setEmail] = useState('');

  const [subscibeMutation] = useSubscribeMutation();

  async function subscribe() {
    if (!EMAIL_PATTERN.test(email)) {
      message.error(`${email} is not a valid email.`);
      return;
    }
    try {
      await subscibeMutation({ email }).unwrap();
      message.success('Thank you for subscribing!');
    } catch (error) {
      if (error instanceof BackendError && error.code === BackendError.Codes.BadRequest) {
        message.success(`${email} is already subscribed to list NEO Smart Economy.`);
        return;
      }
      throw error;
    }
  }

  return (
    <div className={twMerge('', className)} {...props}>
      <div className="hidden bg-[#353945] sm:block sm:h-px" />

      <Container className="flex flex-col items-start justify-between sm:flex-row">
        <img className="mt-[32px] sm:my-[80px]" src={neo} />

        <div className="mt-[32px] flex flex-col items-start space-y-[24px] sm:my-[80px]">
          <Link type="text" size="sm" href="/#home">
            Home
          </Link>
          <Link type="text" size="sm" href="/#tour-map">
            Tour Map
          </Link>
          <Link type="text" size="sm" href="/#partners">
            Guests
          </Link>
          <Link type="text" size="sm" to="/nfts">
            NFTs
          </Link>
        </div>

        <div className="mt-[32px] block h-px self-stretch bg-[#353945] sm:hidden" />
        <div className="hidden w-px self-stretch bg-[#353945] sm:block" />

        <div className="mt-[32px] sm:my-[80px]">
          <div className="text-[12px] font-bold uppercase">Contact</div>
          <div className="mt-[40px] space-y-[8px]">
            <Link className="font-normal" type="text" size="sm" href="mailto:eg@neo.org">
              eg@neo.org
            </Link>
          </div>
        </div>

        <div className="mt-[32px] block h-px self-stretch bg-[#353945] sm:hidden" />
        <div className="hidden w-px self-stretch bg-[#353945] sm:block" />

        <div className="mt-[32px] sm:my-[80px]">
          <div className="text-[12px] font-bold uppercase">Newsletter</div>
          <div className="mt-[40px] w-full text-[14px] leading-loose sm:w-[256px]">
            Subscribe to our newsletter. Keep up to date on all the news, events and developments.
          </div>
          <div
            className={`mt-[24px] flex h-[48px] w-full rounded-[10px] border pl-[16px] pr-[8px] transition-all duration-300 sm:w-[256px] ${
              focused ? 'border-white' : 'border-[#353945]'
            }`}
          >
            <Input
              className="p-0 text-[14px] text-white"
              bordered={false}
              placeholder="Enter your email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <Button className="ml-[8px]" type="image" onClick={subscribe} disabled>
              <img src={arrowRight} />
            </Button>
          </div>
        </div>
      </Container>

      <div className="mt-[32px] h-px bg-[#353945] sm:mt-0" />

      <Container className="flex flex-col justify-between py-[24px] sm:flex-row sm:items-center">
        <div className="text-[12px] text-[#777E90]">Build By © 2022 Neo. All rights reserved</div>
        <div className="mt-[24px] space-x-[24px] sm:mt-0">
          <Mediums />
        </div>
      </Container>
    </div>
  );
};
