import { createApi } from '@reduxjs/toolkit/query/react';
import { subscribeBaseQuery } from 'utils/queries';

export const subscribeApi = createApi({
  reducerPath: 'subscribeApi',
  baseQuery: subscribeBaseQuery(),
  endpoints: builder => ({
    subscribe: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: '/subscribe/post-json',
        params: {
          u: '228d6f3157920dc1cad664104',
          id: '4f3883ac10',
          EMAIL: email,
        },
      }),
    }),
  }),
});

export const { useSubscribeMutation } = subscribeApi;
