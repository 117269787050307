import { Popover } from 'antd';
import { ComponentProps, CSSProperties, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import disconnectImage from 'assets/images/shared/disconnect.svg';
import times from 'assets/images/shared/times.svg';
import { Button } from 'components/base/Button';
import { useDispatch, useStore } from 'hooks/redux';
import { setConnectWalletVisible, useConnectWalletVisible } from 'store/slices/uiState';
import {
  clearLastWalletName,
  saveLastWalletName,
  selectWalletState,
  useConnectedWalletState,
  useWalletStates,
} from 'store/slices/walletStates';
import { WALLET_INFOS } from 'utils/configs';
import { WalletName } from 'utils/enums';
import { formatLongText } from 'utils/formatters';
import { getWallet } from 'utils/wallets';

export const Wallets: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const walletState = useConnectedWalletState();

  const walletsPopoverVisible = useConnectWalletVisible();

  const store = useStore();

  const dispatch = useDispatch();

  const walletStates = useWalletStates();

  const connect = async (walletName: WalletName) => {
    const walletState = selectWalletState(store.getState(), { walletName });
    if (walletState.installed === false) {
      window.open(WALLET_INFOS[walletName].downloadUrl);
      return;
    }
    const wallet = await getWallet(walletName);
    await wallet.connect();
    dispatch(saveLastWalletName(walletName));
    dispatch(setConnectWalletVisible(false));
  };

  const disconnect = async () => {
    if (!walletState) {
      return;
    }
    const wallet = await getWallet(walletState.name);
    await wallet.disconnect();
    dispatch(clearLastWalletName());
  };

  return (
    <div className={twMerge('', className)} {...props}>
      {walletState ? (
        <div className="group relative" onClick={disconnect}>
          <Button
            className="h-[56px] rounded-[5px] sm:h-[40px] sm:rounded-full sm:group-hover:opacity-0"
            type="outline"
            color="white"
            size="sm"
          >
            <img className="h-[16px] w-[16px]" src={WALLET_INFOS[walletState.name].image} />
            <div className="ml-[8px]">
              {formatLongText(walletState.address, { headTailLength: 5 })}
            </div>
            <img className="ml-[8px] sm:hidden" src={times} />
          </Button>
          <Button
            className="absolute inset-0 hidden opacity-0 group-hover:opacity-100 sm:inline-flex"
            type="filled"
            color="red"
            size="sm"
          >
            <img src={disconnectImage} />
            <div className="ml-[8px]">Disconnect</div>
          </Button>
        </div>
      ) : (
        <Popover
          visible={walletsPopoverVisible}
          onVisibleChange={visible => dispatch(setConnectWalletVisible(visible))}
          overlayStyle={{ '--popover-border-radius': '10px' } as CSSProperties}
          trigger="click"
          content={
            <div className="min-w-[200px] bg-[#010E29]">
              <div className="rounded-[10px] bg-gradient-to-b from-white/20 to-white/0 p-[2px]">
                <div className="flex flex-col space-y-[8px] rounded-[10px] bg-[#010E29] p-[24px]">
                  {Object.values(WALLET_INFOS)
                    .filter(info => info.disabled !== true)
                    .map(info => (
                      <Button
                        key={info.name}
                        className="justify-start px-[24px]"
                        type="outline"
                        size="sm"
                        color="white"
                        disabled={walletStates[info.name].installed == undefined}
                        onClick={() => connect(info.name)}
                      >
                        <img src={info.image} />
                        <div className="ml-[8px]">{info.name}</div>
                      </Button>
                    ))}
                </div>
              </div>
            </div>
          }
        >
          <Button
            className="h-[56px] rounded-[5px] sm:h-[40px] sm:rounded-full"
            type="outline"
            color="white"
            size="sm"
          >
            Connect Wallet
          </Button>
        </Popover>
      )}
    </div>
  );
};
