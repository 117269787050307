import { ComponentProps, FC, Fragment } from 'react';
import discord from 'assets/images/shared/discord.svg';
import facebook from 'assets/images/shared/facebook.svg';
import twitter from 'assets/images/shared/twitter.svg';
import youtube from 'assets/images/shared/youtube.svg';
import { Link } from 'components/base/Link';

export const Mediums: FC<ComponentProps<typeof Fragment>> = props => {
  return (
    <Fragment {...props}>
      <Link type="image" href="https://twitter.com/Neo_Blockchain">
        <img src={twitter} />
      </Link>
      <Link type="image" href="https://discord.com/invite/rvZFQ5382k">
        <img src={discord} />
      </Link>
      <Link type="image" href="https://www.facebook.com/NeoBlockchainOfficial">
        <img src={facebook} />
      </Link>
      <Link type="image" href="https://www.youtube.com/c/NeoSmartEconomy">
        <img src={youtube} />
      </Link>
    </Fragment>
  );
};
