import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Modal } from 'antd';
import { ComponentProps, CSSProperties, FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import times from 'assets/images/nfts/times.svg';
import { Button } from 'components/base/Button';
import { useTransferMutation } from 'store/apis/invoke';
import { useConnectedWalletState } from 'store/slices/walletStates';
import { NFT_CATEGORIES, STATIONS } from 'utils/configs';
import { DEPRECATED_NULL } from 'utils/misc';
import { Nft } from 'utils/models';
import { validators } from 'utils/validators';

interface Props extends ComponentProps<typeof Modal> {
  nft: Nft;
  onOk: () => void;
}

export const Transfer: FC<Props> = ({ nft, onOk, onCancel, ...props }) => {
  const [focused, setFocused] = useState(false);

  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: '' as string,
    },
    resolver: yupResolver(
      validators.object({
        address: validators.string().label('Address').required().isN3Address(),
      }),
    ),
  });

  const nftCategory = NFT_CATEGORIES.find(nftCategory => nftCategory.name === nft.category);

  const station = STATIONS.find(station => station.nftCategories.includes(nft.category));

  const walletState = useConnectedWalletState();

  const [transferMutation, { isLoading }] = useTransferMutation();

  async function transfer() {
    if (!walletState) {
      return;
    }
    const values = getValues();
    await transferMutation({
      address: walletState.address,
      toAddress: values.address,
      tokenId: nft.id,
    }).unwrap();
    onOk?.();
  }

  return (
    <Modal
      centered
      width={468}
      bodyStyle={{ padding: 0 }}
      style={{ '--modal-border-radius': '24px' } as CSSProperties}
      footer={DEPRECATED_NULL}
      closable={false}
      destroyOnClose
      onCancel={onCancel}
      {...props}
    >
      <div className="rounded-[24px] bg-gradient-to-b from-white/100 to-white/0 p-px">
        <div className="rounded-[24px] bg-[#010E29] p-[40px]">
          <div className="flex justify-between">
            <div className="text-[24px] font-semibold">Transfer NFT</div>
            <Button type="image" onClick={onCancel}>
              <img src={times} />
            </Button>
          </div>
          <div className="mt-[40px] flex items-center rounded-[6px] border border-white/10 p-[16px]">
            <img className="h-[54px] w-[43px] shrink-0 rounded-[6px]" src={nftCategory?.image} />
            <div className="ml-[14px]">
              <div className="text-[20px] font-semibold">{nft.name}</div>
              <div className="mt-[10px] text-[14px] uppercase">
                USA Campus Tour - {station?.name}
              </div>
            </div>
          </div>

          <div className="mt-[40px] text-[14px]">Transfer to</div>

          <div
            className={`mt-[24px] flex h-[48px] w-full rounded-[6px] border px-[16px] transition-all duration-300 ${
              focused ? 'border-white' : 'border-white/10'
            }`}
          >
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <Input
                  className="p-0 text-[14px] text-white"
                  bordered={false}
                  placeholder="Enter the receiving address on Neo N3"
                  autoComplete="off"
                  {...field}
                  onFocus={() => setFocused(true)}
                  onBlur={() => {
                    setFocused(false);
                    field.onBlur();
                  }}
                />
              )}
            />
          </div>
          {errors.address != undefined && (
            <div className="mt-[8px] text-[14px] text-[#FF7E6B]">{errors.address.message}</div>
          )}

          <Button
            className="mt-[72px] w-full"
            type="outline"
            size="lg"
            disabled={!walletState}
            loading={isLoading}
            onClick={handleSubmit(transfer)}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
