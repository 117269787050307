import daHongFei from 'assets/images/common/guests/da-hongfei.png';
import deanJeffs from 'assets/images/common/guests/dean-jeffs.png';
import dylanGrabowski from 'assets/images/common/guests/dylan-grabowski.png';
import guilSperbMachado from 'assets/images/common/guests/guil-sperb-machado.png';
import harryPierson from 'assets/images/common/guests/harry-pierson.png';
import johnDevadoss from 'assets/images/common/guests/john-devadoss.png';
import johnWang from 'assets/images/common/guests/john-wang.png';
import stevenLiu from 'assets/images/common/guests/steven-liu.png';
import tylerAdams from 'assets/images/common/guests/tyler-adams.png';
import nft1624 from 'assets/images/common/nft-categories/1624.png';
import nft1636 from 'assets/images/common/nft-categories/1636.png';
import nft1701 from 'assets/images/common/nft-categories/1701.png';
import nft1817 from 'assets/images/common/nft-categories/1817.png';
import nft1851 from 'assets/images/common/nft-categories/1851.png';
import nft1861 from 'assets/images/common/nft-categories/1861.png';
import nft1863 from 'assets/images/common/nft-categories/1863.png';
import nft1867 from 'assets/images/common/nft-categories/1867.png';
import nft1868 from 'assets/images/common/nft-categories/1868.png';
import nft1880 from 'assets/images/common/nft-categories/1880.png';
import nft1885 from 'assets/images/common/nft-categories/1885.png';
import nft1919 from 'assets/images/common/nft-categories/1919.png';
import nft1960 from 'assets/images/common/nft-categories/1960.png';
import nft2022 from 'assets/images/common/nft-categories/2022.png';
import astraLaunchLab from 'assets/images/common/partners/astra-launch-lab.png';
import axlabs from 'assets/images/common/partners/axlabs.png';
import bayVentureClub from 'assets/images/common/partners/bay-venture-club.png';
import blockchainAtFordham from 'assets/images/common/partners/blockchain-at-fordham.png';
import blockchainAtSanDiego from 'assets/images/common/partners/blockchain-at-san-diego.png';
import bostonCollegeVentureStudio from 'assets/images/common/partners/boston-college-venture-studio.png';
import coinlive from 'assets/images/common/partners/coinlive.png';
import cointelegraph from 'assets/images/common/partners/cointelegraph.png';
import collegeDao from 'assets/images/common/partners/college-dao.png';
import coz from 'assets/images/common/partners/coz.png';
import cryptogevityAtUiuc from 'assets/images/common/partners/cryptogevity-at-uiuc.png';
import fordhamFintechNetwork from 'assets/images/common/partners/fordham-fintech-network.png';
import globalEntrepreneurshipSocietyAtUcla from 'assets/images/common/partners/global-entrepreneurship-society-at-ucla.png';
import goldenFinance from 'assets/images/common/partners/golden-finance.png';
import harvardCssa from 'assets/images/common/partners/harvard-cssa.png';
import michiganCryptocurrencyInvestmentClub from 'assets/images/common/partners/michigan-cryptocurrency-investment-club.png';
import michiganVentureClub from 'assets/images/common/partners/michigan-venture-club.png';
import neoResearch from 'assets/images/common/partners/neo-research.png';
import neoSpcc from 'assets/images/common/partners/neo-spcc.png';
import next from 'assets/images/common/partners/next.png';
import northwesternCssa from 'assets/images/common/partners/northwestern-cssa.png';
import nuBlockchainGroup from 'assets/images/common/partners/nu-blockchain-group.png';
import nyuBlockchainFintech from 'assets/images/common/partners/nyu-blockchain-fintech.png';
import nyuVioletVentureClub from 'assets/images/common/partners/nyu-violet-venture-club.png';
import positiveVentureDao from 'assets/images/common/partners/positive-venture-dao.png';
import red4sec from 'assets/images/common/partners/red4sec.png';
import stanfordAcsss from 'assets/images/common/partners/stanford-acsss.png';
import studentBusinessConsortiumAtBostonCollege from 'assets/images/common/partners/student-business-consortium-at-boston-college.png';
import uiucCssa from 'assets/images/common/partners/uiuc-cssa.png';
import uscCssa from 'assets/images/common/partners/usc-cssa.png';
import yaleBlockchainClub from 'assets/images/common/partners/yale-blockchain-club.png';
import yaleVentureClub from 'assets/images/common/partners/yale-venture-club.png';
import neoline from 'assets/images/common/wallets/neoline.png';
import neon from 'assets/images/common/wallets/neon.png';
import o3 from 'assets/images/common/wallets/o3.png';
import onegate from 'assets/images/common/wallets/onegate.svg';
import { WalletName } from './enums';
import { TARGET_MAINNET } from './env';
import { Guest, NftCategory, Partner, Station, WalletInfo } from './models';

export const SUBSCRIBE_URL = 'https://neo.us4.list-manage.com';

export const NODE_URL = TARGET_MAINNET
  ? 'https://neofura.ngd.network'
  : 'https://testmagnet.ngd.network';

export const FURA_URL = TARGET_MAINNET
  ? 'https://neofura.ngd.network'
  : 'https://testmagnet.ngd.network';

export const NFT_CONTRACT_HASH = TARGET_MAINNET
  ? '0x4e6238d4372d843d38481eb5a6feca8ea9d44bb4'
  : '0x5423fc51fea5ac443759323bbbccdc922cd3311c';

export const PAY_CONTRACT_HASH = TARGET_MAINNET
  ? '0xec53b72ca5b9c7e856ff77e160a71758c350e63c'
  : '0x25ceb5780f604487d5642bdaf7739bd999f06618';

export const WALLET_INFOS: Record<WalletName, WalletInfo> = {
  [WalletName.OneGate]: {
    name: WalletName.OneGate,
    image: onegate,
    downloadUrl: 'https://onegate.space',
    minimumVersion: '0.0.0',
  },
  [WalletName.NeoLine]: {
    name: WalletName.NeoLine,
    image: neoline,
    downloadUrl:
      'https://chrome.google.com/webstore/detail/neoline/cphhlgmgameodnhkjdmkpanlelnlohao',
    minimumVersion: '0.0.0',
  },
  [WalletName.O3]: {
    name: WalletName.O3,
    image: o3,
    downloadUrl: 'https://o3.network/#download',
    minimumVersion: '0.0.0',
  },
  [WalletName.Neon]: {
    name: WalletName.Neon,
    image: neon,
    downloadUrl: 'https://neonwallet.com',
    minimumVersion: '0.0.0',
  },
};

// TODO: Update this constant when a station arrives (Make sure the name is consistent to the configs)
export const CURRENT_STATION = 'New York City';

export const STATIONS: Station[] = [
  {
    name: 'Yale University',
    coordinate: { x: 953, y: 196 },
    date: 'Oct.2nd, 2022',
    time: '2022.10.02 14:00-17:00 EST',
    venue: 'Edward P. Evans Hall Room 4200',
    description:
      'Yale University is a private Ivy League research university in New Haven, Connecticut. It is the third-oldest institution of higher education in the United States and among the most prestigious in the world.',
    signUpLink: 'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-tickets-418372662897?aff=neo',
    nftCategories: ['1701'],
    guests: ['John Wang', 'Steven Liu', 'Guil. Sperb Machado'],
  },
  {
    name: 'Boston College',
    coordinate: { x: 967, y: 142 },
    date: 'Oct.3rd, 2022',
    time: '2022.10.03',
    venue: 'On Campus',
    description:
      'Boston College is a private Jesuit research university in Chestnut Hill, Massachusetts. Although Boston College is classified as an R1 research university, it still uses the word "college" in its name to reflect its historical position as a small liberal arts college.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-boston-college-tickets-425585767487?aff=Neo',
    nftCategories: ['1863'],
    guests: ['John Wang', 'Steven Liu', 'Guil. Sperb Machado'],
  },
  {
    name: 'Massachusetts Institute of Technology',
    coordinate: { x: 936, y: 133 },
    date: 'Oct.4th, 2022',
    time: '2022.10.04',
    venue: 'On Campus',
    description:
      'The Massachusetts Institute of Technology (MIT) is a private land-grant research university in Cambridge, Massachusetts. Established in 1861, MIT has since played a key role in the development of modern technology and science, ranking among the top academic institutions in the world.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-mit-tickets-425587683217?aff=Neo',
    nftCategories: ['1861'],
    guests: ['Da Hongfei', 'Steven Liu', 'Guil. Sperb Machado'],
  },
  {
    name: 'Harvard University',
    coordinate: { x: 909, y: 155 },
    date: 'Oct.5th, 2022',
    time: '2022.10.05',
    venue: 'Science and Engineering Complex Room 2.122',
    description:
      'Harvard University is a private Ivy League research university in Cambridge, Massachusetts. Founded in 1636, it is the oldest institution of higher learning in the United States and regarded as one of the most prestigious in the world.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-harvard-university-tickets-424919755427?aff=Neo',
    nftCategories: ['1636'],
    guests: ['John Wang', 'Steven Liu', 'Guil. Sperb Machado'],
  },
  {
    name: 'New York City',
    coordinate: { x: 930, y: 223 },
    date: 'Oct.8th, 2022',
    time: '2022.10.08',
    venue: 'The Knickerbocker',
    description:
      'Neo will co-host an interactive workshop event at NYC with several established university clubs.\n\nSchools included: NYU, Columbia, Fordham, Cornell and Parsons.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-nyc-workshop-tickets-423918290017?aff=Neo',
    nftCategories: ['1624', '2022'],
    guests: ['John Wang', 'Steven Liu', 'Guil. Sperb Machado'],
  },
  {
    name: 'University of Michigan',
    coordinate: { x: 754, y: 226 },
    date: 'Oct.12th, 2022',
    time: '2022.10.12 18:00-20:00 EST',
    venue: 'Palmer Commons Forum Hall',
    description:
      "The University of Michigan is a public research university in Ann Arbor, Michigan. Founded in 1817 by an act of the old Michigan Territory, as the Catholepistemiad, or the University of Michigania, 20 years before the territory became a state, the university is Michigan's oldest.",
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-uni-michigan-tickets-427743350877?aff=Neo',
    nftCategories: ['1817'],
    guests: ['John deVadoss', 'Tyler Adams', 'Dylan Grabowski'],
  },
  {
    name: 'Northwestern University',
    coordinate: { x: 711, y: 231 },
    date: 'Oct.13th, 2022',
    time: '2022.10.13 18:00-20:00 EST',
    venue: 'On Campus',
    description:
      'Northwestern University is a private research university in Evanston, Illinois. Founded in 1851, Northwestern is the oldest chartered university in Illinois and is ranked among the most prestigious academic institutions in the world.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-northwestern-university-tickets-427745667807?aff=Neo',
    nftCategories: ['1851'],
    guests: ['John deVadoss', 'Tyler Adams', 'Dylan Grabowski'],
  },
  {
    name: 'University of Illinois Urbana-Champaign',
    coordinate: { x: 693, y: 282 },
    date: 'Oct.14th, 2022',
    time: '2022.10.14 17:00 EST',
    venue: 'Illini Union Room 314',
    description:
      'The University of Illinois Urbana-Champaign  is a public land-grant research university in Illinois in the twin cities of Champaign and Urbana. It is the flagship institution of the University of Illinois system and was founded in 1867. It is one of the largest public universities by enrollment in the nation.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-university-of-illinois-urbana-champaign-tickets-427747342817?aff=Neo',
    nftCategories: ['1867'],
    guests: ['John deVadoss', 'Tyler Adams', 'Dylan Grabowski'],
  },
  {
    name: 'University of California Los Angeles',
    coordinate: { x: 181, y: 415 },
    date: 'Oct.17th, 2022',
    time: '2022.10.17 18:30-20:30 EST',
    venue: 'UCLA Classroom',
    description:
      "The University of California, Los Angeles  is a public land-grant research university in Los Angeles, California. UCLA’s academic roots were established in 1881. UCLA is considered one of the country's Public Ivies, and is frequently ranked among the best universities in the world.",
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-university-of-california-los-angeles-tickets-432065548687?aff=Neo',
    nftCategories: ['1919'],
    guests: ['John deVadoss', 'Harry Pierson', 'Dylan Grabowski'],
  },
  {
    name: 'University of Southern California',
    coordinate: { x: 224, y: 440 },
    date: 'Oct.18th, 2022',
    time: '2022.10.18 18:00-20:00 EST',
    venue: 'On Campus',
    description:
      'The University of Southern California is a private research university in Los Angeles, California. Founded in 1880 by Robert M. Widney, it is the oldest private research university in California.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-university-of-southern-california-tickets-431487279067?aff=Neo',
    nftCategories: ['1880'],
    guests: ['John deVadoss', 'Harry Pierson', 'Dylan Grabowski'],
  },
  {
    name: 'University of California San Diego',
    coordinate: { x: 276, y: 464 },
    date: 'Oct.20th, 2022',
    time: '2022.10.20 18:00 EST',
    venue: 'CSE Conference Room 1202',
    description:
      'The University of California, San Diego is a public land-grant research university in San Diego, California. UC San Diego is ranked among the best universities in the world by major college and university rankings.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-university-of-california-san-diego-tickets-432045739437?aff=Neo',
    nftCategories: ['1960'],
    guests: ['John deVadoss', 'Harry Pierson', 'Dylan Grabowski'],
  },
  {
    name: 'University of California Berkeley',
    coordinate: { x: 80, y: 225 },
    date: 'Oct.24th, 2022',
    time: '2022.10.24 18:00 EST',
    venue: 'On Campus',
    description:
      "The University of California, Berkeley is a public land-grant research university in Berkeley, California. Established in 1868 as the University of California, it is the state's first land-grant university and the first campus of the University of California system. ",
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-uc-berkeley-tickets-432017765767?aff=Neo',
    nftCategories: ['1868'],
    guests: ['John deVadoss', 'Harry Pierson', 'Dylan Grabowski'],
  },
  {
    name: 'Stanford University',
    coordinate: { x: 95, y: 294 },
    date: 'Oct.25th, 2022',
    time: '2022.10.25',
    venue: 'Schwab Residential Center',
    description:
      'Stanford University, officially Leland Stanford Junior University,is a private research university in Stanford, California. The campus occupies 8,180 acres (3,310 hectares), among the largest in the United States, and enrolls over 17,000 students. Stanford is ranked among the top universities in the world.',
    signUpLink:
      'https://www.eventbrite.co.uk/e/neo-usa-campus-tour-stanford-tickets-432054916887?aff=Neo',
    nftCategories: ['1885'],
    guests: ['John deVadoss', 'Harry Pierson', 'Dylan Grabowski'],
  },
];

export const EAST_STATIONS_COUNT = 8;

export const NFT_CATEGORIES: NftCategory[] = [
  { name: '1701', image: nft1701 },
  { name: '1863', image: nft1863 },
  { name: '1861', image: nft1861 },
  { name: '1636', image: nft1636 },
  { name: '1624', image: nft1624 },
  { name: '2022', image: nft2022 },
  { name: '1817', image: nft1817 },
  { name: '1851', image: nft1851 },
  { name: '1867', image: nft1867 },
  { name: '1919', image: nft1919 },
  { name: '1880', image: nft1880 },
  { name: '1960', image: nft1960 },
  { name: '1868', image: nft1868 },
  { name: '1885', image: nft1885 },
];

export const NFT_MAX_COUNT = 100;

export const PARTNERS: Partner[] = [
  { name: 'Axlabs', image: axlabs },
  { name: 'Neo Research', image: neoResearch },
  { name: 'COZ', image: coz },
  { name: 'Neo SPCC', image: neoSpcc },
  { name: 'Red4Sec', image: red4sec },
  { name: 'NEXT', image: next },
  { name: 'College Dao', image: collegeDao },
  { name: 'Yale Blockchain Club', image: yaleBlockchainClub },
  { name: 'Yale Venture Club', image: yaleVentureClub },
  { name: 'Michigan Venture Club', image: michiganVentureClub },
  { name: 'Positive Venture Dao', image: positiveVentureDao },
  { name: 'NYU Blockchain&Fintech', image: nyuBlockchainFintech },
  { name: 'NYU Violet Venture Club', image: nyuVioletVentureClub },
  { name: 'Northwestern CSSA', image: northwesternCssa },
  { name: 'NU Blockchain Group', image: nuBlockchainGroup },
  { name: 'Cryptogevity at UIUC', image: cryptogevityAtUiuc },
  { name: 'UIUC CSSA', image: uiucCssa },
  { name: 'Global Entrepreneurship Society at UCLA', image: globalEntrepreneurshipSocietyAtUcla },
  { name: 'Blockchain at San Diego', image: blockchainAtSanDiego },
  { name: 'USC CSSA', image: uscCssa },
  { name: 'Astra Launch Lab', image: astraLaunchLab },
  { name: 'Stanford ACSSS', image: stanfordAcsss },
  { name: 'Bay Venture Club', image: bayVentureClub },
  { name: 'Michigan Cryptocurrency Investment Club', image: michiganCryptocurrencyInvestmentClub },
  { name: 'Harvard CSSA', image: harvardCssa },
  { name: 'Boston College Venture Studio', image: bostonCollegeVentureStudio },
  {
    name: 'Student Business Consortium at Boston College',
    image: studentBusinessConsortiumAtBostonCollege,
  },
  { name: 'Fordham Fintech Network', image: fordhamFintechNetwork },
  { name: 'Blockchain at Fordham', image: blockchainAtFordham },
  { name: '金色财经', image: goldenFinance },
  { name: 'Coinlive', image: coinlive },
  { name: 'Cointelegraph', image: cointelegraph },
];

export const GUESTS: Guest[] = [
  {
    name: 'Da Hongfei',
    title: 'Founder of Neo',
    image: daHongFei,
    discord: 'HD#8889',
    twitter: 'https://twitter.com/dahongfei',
  },
  {
    name: 'John deVadoss',
    title: 'President of NGD Enterprise',
    image: johnDevadoss,
    discord: 'Johnd#9813',
    twitter: 'https://twitter.com/john_devadoss',
  },
  {
    name: 'John Wang',
    title: 'Head of NGD Ecosystem Growth',
    image: johnWang,
    discord: 'John Wang#4291',
    twitter: 'https://twitter.com/wjcbaggio',
  },
  {
    name: 'Steven Liu',
    title: 'Head of NGD Development',
    image: stevenLiu,
    discord: 'Steven#3850',
    twitter: 'https://twitter.com/rendongliu',
  },
  {
    name: 'Guil. Sperb Machado',
    title: 'Founder of AxLabs',
    image: guilSperbMachado,
    discord: 'guil#4237',
    twitter: 'https://twitter.com/gsmachado',
  },
  {
    name: 'Harry Pierson',
    title: 'Chief Architect of NGD Enterprise',
    image: harryPierson,
    discord: 'devhawk#5570',
    twitter: 'https://twitter.com/devhawk',
  },
  {
    name: 'Tyler Adams',
    title: 'Founder and Executive Officer of COZ',
    image: tylerAdams,
    discord: 'tyler#5857',
    twitter: 'https://twitter.com/lllwvlvwlll',
  },
  {
    name: 'Dean Jeffs',
    title: 'Founder of Neo News Today',
    image: deanJeffs,
    discord: 'dean#5746',
    twitter: 'https://twitter.com/NEOnewstoday',
  },
  {
    name: 'Dylan Grabowski',
    title: 'Editor of Neo News Today',
    image: dylanGrabowski,
    discord: 'Dylan#0172',
    twitter: 'https://twitter.com/GrabowskiDylan',
  },
];
