import { useBreakpoint } from '@liuqiang1357/react-breakpoints';
import { ComponentProps, CSSProperties, FC, useState } from 'react';
import { useMeasure } from 'react-use';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Pagination, Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import arrowLeft from 'assets/images/index/arrow-left.svg';
import arrowRight from 'assets/images/index/arrow-right.svg';
import calendar from 'assets/images/index/calendar.svg';
import map from 'assets/images/index/map.svg';
import markerVisited from 'assets/images/index/marker-visited.svg';
import marker from 'assets/images/index/marker.svg';
import room from 'assets/images/index/room.svg';
import { Button } from 'components/base/Button';
import { Container } from 'components/shared/Container';
import { EAST_STATIONS_COUNT, GUESTS, STATIONS } from 'utils/configs';
import { BREAKPOINTS, CURRENT_STATION_INDEX, scrollTo } from 'utils/misc';
import { Station } from 'utils/models';
import { StationPopover } from './StationPopover';

export const TourMap: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [currentIndex, setCurrentIndex] = useState(CURRENT_STATION_INDEX);

  const [stationPopoverVisible, setStationPopoverVisible] = useState(false);

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const breakpoint = useBreakpoint(BREAKPOINTS);

  const renderStationSummary = (station: Station, index: number) => {
    return (
      <div className="relative flex w-[508px] flex-col items-center">
        {index !== STATIONS.length - 1 && (
          <div className="absolute top-1/2 right-0 flex -translate-y-1/2 translate-x-1/2 items-center space-x-[4px]">
            <div className="h-[12px] w-[12px] rounded-full border-2 border-[#B1B5C4]" />
            <div className="w-[123px] border-b border-dashed border-[#B1B5C4]" />
            <div className="h-[12px] w-[12px] rounded-full border-2 border-[#B1B5C4]" />
          </div>
        )}
        <div className="flex w-[300px] flex-col items-center">
          <div className="font-semibold text-[#00E7A2]">{station.date}</div>
          <div className="mt-[32px] text-center text-[24px] font-bold">{station.name}</div>
          <Button
            className="mt-[48px]"
            type="outline"
            size="sm"
            disabled={station.signUpLink == undefined}
            onClick={() => window.open(station.signUpLink)}
          >
            Sign up
          </Button>
        </div>
      </div>
    );
  };

  const renderMap = ({ showStation = false } = {}) => (
    <div className="relative">
      <img className="h-[664px] w-[1055px]" src={map} />
      {STATIONS.map((info, index) => (
        <Button
          key={index}
          className={`absolute left-[var(--x)] top-[var(--y)] -translate-x-1/2 -translate-y-full ${
            (breakpoint === 'base' ? currentIndex === index : CURRENT_STATION_INDEX === index)
              ? 'origin-bottom scale-[1.4]'
              : ''
          }`}
          style={
            { '--x': `${info.coordinate.x}px`, '--y': `${info.coordinate.y}px` } as CSSProperties
          }
          type="image"
          onClick={() => {
            setCurrentIndex(index);
            swiper?.slideTo(index);
            setStationPopoverVisible(true);
            if (breakpoint === 'base') {
              scrollTo('stations');
            }
          }}
        >
          <img src={index <= CURRENT_STATION_INDEX ? markerVisited : marker} />
        </Button>
      ))}

      {showStation && stationPopoverVisible && (
        <StationPopover
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          station={STATIONS[currentIndex]}
          onClose={() => setStationPopoverVisible(false)}
        />
      )}
    </div>
  );

  const renderStation = (station: Station) => {
    const guests = station.guests.map(guestName => GUESTS.find(guest => guest.name === guestName));
    return (
      <div className="flex h-full flex-col justify-between rounded-[10px] bg-white/[0.15] px-[24px] py-[40px]">
        <div className="text-[20px] font-semibold">{station.name}</div>

        <div className="mt-[16px]	whitespace-pre-line text-[10px]">{station.description}</div>

        <div className="mt-[16px]">
          <div className="flex items-center">
            <img className="h-[14px] w-[14px]" src={calendar} />
            <div className="ml-[8px] text-[11px]">{station.time}</div>
          </div>

          <div className="mt-[8px] flex items-center">
            <img className="h-[14px] w-[14px]" src={room} />
            <div className="ml-[8px] text-[11px]">{station.venue}</div>
          </div>
        </div>

        <div className="mt-[16px] grid grid-cols-4 justify-between gap-x-[8px]">
          {guests.map(guest => (
            <div key={guest?.name} className="flex flex-col items-center">
              <img className="h-[40px] w-[40px] rounded-full bg-white/10" src={guest?.image} />
              <div className="mt-[16px] text-center text-[9px] font-semibold">{guest?.name}</div>
            </div>
          ))}
        </div>

        <Button
          className="mt-[16px] self-start text-[12px]"
          type="outline"
          size="sm"
          disabled={station.signUpLink == undefined}
          onClick={() => window.open(station.signUpLink)}
        >
          Sign up
        </Button>
      </div>
    );
  };

  return (
    <div className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex flex-col items-center sm:hidden">
        <div className="text-[12px] font-medium uppercase text-[#00E599]">Our Tour</div>
        <div className="mt-[8px] text-[32px] font-light uppercase">Map</div>
      </div>

      <div className="hidden flex-col items-center sm:flex">
        <div className="font-worksans text-[40px] font-bold uppercase">Our Tour Map</div>
        <div className="mt-[20px] w-[544px] text-center text-[#E6E8EC]">
          We will visit these prestigious universities this October. Click the landmarks on the map
          to get detailed information and exclusive NFTs for each campus.
        </div>
      </div>

      <div className="mt-[80px] hidden sm:block">
        <Swiper
          className="max-w-fit pb-[80px]"
          slidesPerView="auto"
          initialSlide={CURRENT_STATION_INDEX}
          pagination={{ clickable: true }}
          modules={[Pagination]}
        >
          {STATIONS.map((station, index) => (
            <SwiperSlide key={station.name} className="flex h-auto w-fit items-center">
              {() => renderStationSummary(station, index)}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Container className="mt-[12px] sm:hidden">
        <div
          ref={r => r && ref(r)}
          className="h-[400px] overflow-hidden rounded-[10px] border-2 border-[#777E91]"
        >
          {width > 0 && (
            <TransformWrapper
              minScale={width / 1055}
              maxScale={1}
              initialScale={0.7}
              initialPositionX={
                CURRENT_STATION_INDEX <= EAST_STATIONS_COUNT - 1 ? -(1055 * 0.7 - width) : 0
              }
            >
              <TransformComponent wrapperClass="!h-full !w-full">{renderMap()}</TransformComponent>
            </TransformWrapper>
          )}
        </div>
      </Container>

      <div className="mt-[136px] hidden self-center sm:block">
        {renderMap({ showStation: true })}
      </div>

      <div className="mt-[20px] flex self-center sm:hidden">
        <Button
          type="image"
          disabled={currentIndex <= 0}
          onClick={() => {
            setCurrentIndex(currentIndex - 1);
            swiper?.slideTo(currentIndex - 1);
          }}
        >
          <img src={arrowLeft} />
        </Button>
        <Button
          className="ml-[20px]"
          type="image"
          disabled={currentIndex >= STATIONS.length - 1}
          onClick={() => {
            setCurrentIndex(currentIndex + 1);
            swiper?.slideTo(currentIndex + 1);
          }}
        >
          <img src={arrowRight} />
        </Button>
      </div>

      <div className="mt-[32px] flex flex-col items-center sm:hidden">
        <div id="stations" className="mt-[-100px] mb-[100px]" />
        <div className="font-light text-[#00E599]">Click the location</div>
        <div className="mt-[8px] text-[32px] font-light">For more details</div>
      </div>

      <div className="mt-[40px] block overflow-hidden sm:hidden">
        <Container>
          <Swiper
            className="max-w-fit overflow-visible"
            spaceBetween={16}
            slidesPerView="auto"
            slideToClickedSlide
            initialSlide={CURRENT_STATION_INDEX}
            onSwiper={setSwiper}
            onSlideChange={swiper => setCurrentIndex(swiper.activeIndex)}
          >
            {STATIONS.map(station => (
              <SwiperSlide key={station.name} className="h-auto">
                {() => renderStation(station)}
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>

      <div className="mt-[20px] flex self-center sm:hidden">
        <Button
          type="image"
          disabled={currentIndex <= 0}
          onClick={() => {
            setCurrentIndex(currentIndex - 1);
            swiper?.slideTo(currentIndex - 1);
          }}
        >
          <img src={arrowLeft} />
        </Button>
        <Button
          className="ml-[20px]"
          type="image"
          disabled={currentIndex >= STATIONS.length - 1}
          onClick={() => {
            setCurrentIndex(currentIndex + 1);
            swiper?.slideTo(currentIndex + 1);
          }}
        >
          <img src={arrowRight} />
        </Button>
      </div>
    </div>
  );
};
