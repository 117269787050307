import { FC } from 'react';
import { Page } from 'components/shared/Page';
import { Banner } from './Banner';
import { Partners } from './Partners';
import { TourMap } from './TourMap';

export const Index: FC = () => {
  return (
    <Page contentClassName="pb-[60px] flex flex-col">
      <Banner id="home" />
      <div id="tour-map" className="mt-[-20px] mb-[20px]" />
      <TourMap />
      <div id="partners" className="mt-[40px] mb-[-40px]" />
      <Partners className="mt-[60px]" />
    </Page>
  );
};
