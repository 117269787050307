import BigNumber from 'bignumber.js';
import i18n from 'i18next';

export interface FormatEnumOptions {
  defaultValue?: string;
  ingoreMissing?: boolean;
}

export function formatEnum(
  type: string,
  value: number | string | undefined,
  { defaultValue, ingoreMissing = false }: FormatEnumOptions = {},
): string {
  if (value == undefined) {
    return '-';
  }
  if (i18n.exists(`common:enums.${type}.${value}`)) {
    return i18n.t(`common:enums.${type}.${value}`);
  }
  if (!ingoreMissing) {
    console.warn(`Enum path not found: ${`common:enums.${type}.${value}`}`);
  }
  return String(defaultValue ?? value);
}

export interface FormatValidationsOptions {
  ingoreMissing?: boolean;
}

export function formatValidation(
  key: string,
  params: Record<string, any>,
  { ingoreMissing = false }: FormatValidationsOptions = {},
): string {
  if (i18n.exists(`common:validations.${key}`)) {
    return i18n.t(`common:validations.${key}`, params);
  }
  if (!ingoreMissing) {
    console.warn(`Validation path not found: ${key}`);
  }
  if (i18n.exists('common:validations.mixed.default')) {
    return i18n.t(`common:validations.mixed.default`, params);
  }
  return '-';
}

export interface FormatNumberOptions {
  decimals?: number | string;
  roundingMode?: BigNumber.RoundingMode;
  prefix?: string;
  suffix?: string;
}

export function formatNumber(
  value: number | string | undefined,
  { decimals, roundingMode, prefix, suffix }: FormatNumberOptions = {},
): string {
  if (value == undefined) {
    return '-';
  }
  const bn = new BigNumber(value);
  if (bn.isNaN()) {
    return '-';
  }
  const options = {
    prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    suffix,
  };
  if (decimals != undefined) {
    return bn.dp(Number(decimals), roundingMode).toFormat(options);
  }
  return bn.toFormat(options);
}

export interface FormatLongTextOptions {
  headTailLength?: number;
  headLength?: number;
  tailLength?: number;
}

export function formatLongText(
  value: string | undefined,
  { headTailLength = 8, headLength, tailLength }: FormatLongTextOptions = {},
): string {
  if (value == undefined) {
    return '-';
  }
  const finalHeadLength = headLength ?? headTailLength;
  const finalTailLength = tailLength ?? headTailLength;

  if (value.length <= finalHeadLength + finalTailLength + 3) {
    return value;
  }
  return `${value.slice(0, finalHeadLength)}...${value.slice(value.length - finalTailLength)}`;
}
