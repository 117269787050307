import { skipToken } from '@reduxjs/toolkit/dist/query';
import queryString from 'query-string';
import { ComponentProps, FC, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import arrowLeft from 'assets/images/nfts/arrow-left.svg';
import arrowRight from 'assets/images/nfts/arrow-right.svg';
import share from 'assets/images/nfts/share.svg';
import { Button } from 'components/base/Button';
import { Link } from 'components/base/Link';
import { Container } from 'components/shared/Container';
import { useGetNftsQuery } from 'store/apis/fura';
import { useConnectedWalletState } from 'store/slices/walletStates';
import { NFT_CATEGORIES } from 'utils/configs';
import { Nft } from 'utils/models';
import { Transfer } from './Transfer';

export const MyCollection: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [transferVisible, setTransferVisible] = useState(false);

  const walletState = useConnectedWalletState();

  const { currentData: nfts } = useGetNftsQuery(
    walletState
      ? {
          address: walletState.address,
        }
      : skipToken,
  );

  const currentNft = nfts?.[currentIndex] ?? nfts?.[0];

  const currentNftCategory =
    currentNft && NFT_CATEGORIES.find(nftCategory => nftCategory.name === currentNft.category);

  const tweetUrl = `https://twitter.com/intent/tweet?${queryString.stringify({
    text: 'Check out Neo’s USA Decoding Web3 Campus Tour here: https://tour.neo.org ',
  })}`;

  const renderNft = (nft: Nft, index: number) => {
    const nftCategory = NFT_CATEGORIES.find(nftCategory => nftCategory.name === nft.category);

    return (
      <div
        className="flex cursor-pointer flex-col items-center"
        onClick={() => setCurrentIndex(index)}
      >
        <img
          className={`h-[256px] w-[256px] overflow-hidden rounded-[8px] object-cover transition-all duration-300 ${
            currentIndex === index ? '' : 'origin-bottom scale-[0.9]'
          }`}
          src={nftCategory?.image}
        />
        <div className="mt-[24px] font-semibold">{nft.name}</div>
      </div>
    );
  };

  return (
    <div className={twMerge('flex flex-col', className)} {...props}>
      {currentNft && currentNftCategory && (
        <Container className="flex flex-col items-center sm:flex-row">
          <div className="rounded-[10px] bg-gradient-to-b from-white to-white/0 p-[5px]">
            <img
              className="w-full rounded-[5px] sm:h-[558px] sm:w-[441px]"
              src={currentNftCategory.image}
            />
          </div>

          <div className="mt-[40px] sm:mt-0 sm:ml-[93px]">
            <div className="text-[32px] font-semibold">{currentNft.name}</div>
            <div className="mt-[20px]">
              Standing at the door that connects Web2 and Web3 world, Neo, as one of the most
              established blockchain in the space, will visit renowned universities in the US and
              create a series of NFTs that combine landmarks of each location and the color of each
              campus.
            </div>

            <div className="mt-[32px] h-px bg-[#353945]" />

            <Link
              className="mt-[32px] font-bold"
              type="text"
              href="https://neomarketing.notion.site/NFT-Claim-Instruction-1e5eda82fa4a48bd9366be0aac89356c"
            >
              <img src={share} />
              <div className="ml-[10px]">INSTRUCTION OF CONNECT WALLET AND CLAIM NFT</div>
            </Link>
            <div className="mt-[32px] flex flex-wrap gap-[10px]">
              <Button
                type="outline"
                size="lg"
                color="white"
                onClick={() => setTransferVisible(true)}
              >
                Transfer
              </Button>
              <Button type="outline" size="lg" color="white" onClick={() => window.open(tweetUrl)}>
                Share
              </Button>
            </div>
          </div>
        </Container>
      )}

      {nfts && nfts.length !== 0 && (
        <>
          <Container className="mt-[56px] flex justify-start sm:mt-[80px] sm:justify-center">
            <Button
              type="image"
              disabled={currentIndex <= 0}
              onClick={() => {
                setCurrentIndex(currentIndex - 1);
                swiper?.slideTo(currentIndex - 1);
              }}
            >
              <img src={arrowLeft} />
            </Button>
            <Button
              className="ml-[20px]"
              type="image"
              disabled={currentIndex >= nfts.length - 1}
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
                swiper?.slideTo(currentIndex + 1);
              }}
            >
              <img src={arrowRight} />
            </Button>
          </Container>

          <div className="mt-[56px] overflow-hidden sm:mt-[80px]">
            <Container>
              <Swiper
                className="max-w-fit overflow-visible"
                spaceBetween={32}
                slidesPerView="auto"
                slideToClickedSlide
                onSwiper={setSwiper}
              >
                {nfts?.map((nft, index) => (
                  <SwiperSlide key={index} className="w-fit">
                    {() => renderNft(nft, index)}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Container>
          </div>
        </>
      )}

      {currentNft && (
        <Transfer
          nft={currentNft}
          visible={transferVisible}
          onCancel={() => setTransferVisible(false)}
          onOk={() => setTransferVisible(false)}
        />
      )}
    </div>
  );
};
