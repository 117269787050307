import { ComponentProps, FC, useRef } from 'react';
import { useClickAway } from 'react-use';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import inactiveNft from 'assets/images/common/inactive-nft.svg';
import calendar from 'assets/images/index/calendar.svg';
import close from 'assets/images/index/close.svg';
import room from 'assets/images/index/room.svg';
import { Button } from 'components/base/Button';
import { GUESTS, NFT_CATEGORIES, STATIONS } from 'utils/configs';
import { CURRENT_STATION_INDEX, DEPRECATED_NULL } from 'utils/misc';
import { Station } from 'utils/models';

interface Props extends ComponentProps<'div'> {
  station: Station;
  onClose?: () => void;
}

export const StationPopover: FC<Props> = ({ className, station, onClose, ...props }) => {
  const ref = useRef<HTMLDivElement>(DEPRECATED_NULL);

  useClickAway(ref, () => onClose?.());

  const guests = station.guests.map(guestName => GUESTS.find(guest => guest.name === guestName));

  const nftCategories = station.nftCategories.map(nftCategoryName =>
    NFT_CATEGORIES.find(nftCategory => nftCategory.name === nftCategoryName),
  );

  const stationIndex = STATIONS.indexOf(station);

  return (
    <div ref={ref} className={twMerge('relative', className)} {...props}>
      <div className="absolute inset-0 rounded-[20px] border-[3px] border-white/20 bg-[rgba(1,6,38,0.15)] backdrop-blur-[50px]" />

      <div className="relative flex w-[968px]">
        <Button className="absolute right-[40px] top-[40px]" type="image" onClick={onClose}>
          <img src={close} />
        </Button>

        <div className="flex w-[407px] rounded-[20px] bg-[linear-gradient(130.61deg,var(--tw-gradient-stops))] from-[#5865F2] to-[#00E7A2] p-[5px]">
          {nftCategories.length >= 2 ? (
            <Swiper
              className="w-fit overflow-hidden rounded-[20px]"
              pagination={{ clickable: true }}
              navigation
              modules={[Pagination, Navigation]}
            >
              {nftCategories.map(nftCategory => (
                <SwiperSlide key={nftCategory?.name} className="w-fit">
                  <img
                    className="object-cover"
                    src={stationIndex <= CURRENT_STATION_INDEX ? nftCategory?.image : inactiveNft}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <img
              className="overflow-hidden rounded-[20px] object-cover"
              src={stationIndex <= CURRENT_STATION_INDEX ? nftCategories[0]?.image : inactiveNft}
            />
          )}
        </div>

        <div className="ml-[80px] py-[64px] pr-[80px]">
          <div className="text-[32px] font-semibold">{station.name}</div>

          <div className="mt-[24px] text-[12px]">{station.description}</div>

          <div className="mt-[24px] flex items-center">
            <img src={calendar} />
            <div className="ml-[8px]">{station.time}</div>
          </div>

          <div className="mt-[16px] flex items-center">
            <img src={room} />
            <div className="ml-[8px]">{station.venue}</div>
          </div>

          <div className="mt-[24px] grid grid-cols-4 justify-between gap-x-[8px]">
            {guests.map(guest => (
              <div key={guest?.name} className="flex flex-col items-center">
                <img className="h-[60px] w-[60px] rounded-full bg-white/10" src={guest?.image} />
                <div className="mt-[16px] text-center text-[12px] font-semibold">{guest?.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
