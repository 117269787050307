import { createApi } from '@reduxjs/toolkit/query/react';
import { NFT_CONTRACT_HASH, PAY_CONTRACT_HASH } from 'utils/configs';
import { addressToScriptHash } from 'utils/convertors';
import { DEPRECATED_NULL } from 'utils/misc';
import { invokeBaseQuery } from 'utils/queries';
import { furaApi } from './fura';
import { nodeApi } from './node';

export const invokeApi = createApi({
  reducerPath: 'invokeApi',
  baseQuery: invokeBaseQuery(),
  endpoints: builder => ({
    mint: builder.mutation<void, { address: string; secret: string }>({
      query: ({ address, secret }) => ({
        scriptHash: NFT_CONTRACT_HASH,
        operation: 'mintUniv',
        args: [
          { type: 'Hash160', value: addressToScriptHash(address) },
          { type: 'String', value: secret },
        ],
        signers: [
          { account: PAY_CONTRACT_HASH, scopes: 'CalledByEntry' },
          { account: addressToScriptHash(address), scopes: 'CalledByEntry' },
        ],
        waitConfirmed: true,
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        await queryFulfilled;
        dispatch(nodeApi.util.invalidateTags(['MintedCount']));
        dispatch(nodeApi.util.invalidateTags(['Minted']));
        dispatch(furaApi.util.invalidateTags(['Nft']));
      },
    }),

    transfer: builder.mutation<void, { address: string; toAddress: string; tokenId: string }>({
      query: ({ address, toAddress, tokenId }) => ({
        scriptHash: NFT_CONTRACT_HASH,
        operation: 'transfer',
        args: [
          { type: 'Hash160', value: addressToScriptHash(toAddress) },
          { type: 'ByteArray', value: tokenId },
          { type: 'Any', value: DEPRECATED_NULL },
        ],
        signers: [{ account: addressToScriptHash(address), scopes: 'CalledByEntry' }],
        waitConfirmed: true,
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        await queryFulfilled;
        dispatch(furaApi.util.invalidateTags(['Nft']));
      },
    }),
  }),
});

export const { useMintMutation, useTransferMutation } = invokeApi;
