import { ComponentProps, FC } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import discord from 'assets/images/index/discord.svg';
import twitter from 'assets/images/index/twitter.svg';
import { Button } from 'components/base/Button';
import { Link } from 'components/base/Link';
import { Container } from 'components/shared/Container';
import { useCopyText } from 'hooks/copyText';
import { GUESTS, PARTNERS } from 'utils/configs';
import { Guest, Partner } from 'utils/models';

export const Partners: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const copyText = useCopyText();

  const renderPartner = (partner: Partner) => {
    return (
      <div key={partner.name} className="flex items-center">
        <img className="h-[30px] w-[30px] rounded-full bg-[#D9D9D9]/10" src={partner.image} />
        <div
          className={`ml-[8px] max-w-[120px] font-semibold ${
            partner.name.length >= 20 ? 'text-[10px]' : ''
          }`}
        >
          {partner.name}
        </div>
      </div>
    );
  };

  const renderGuest = (guest: Guest) => {
    return (
      <div key={guest.name} className="flex flex-col items-center">
        <img className="h-[160px] w-[160px] rounded-[10px] bg-white/10" src={guest.image} />
        <div className="mt-[24px] font-medium">{guest.name}</div>
        <div className="mt-[4px] text-[14px] text-[#777E90]">{guest.title}</div>
        <div className="mt-[24px] flex justify-center">
          <Button type="image" onClick={() => copyText(guest.discord)}>
            <img src={discord} />
          </Button>
          <Link className="ml-[8px]" type="image" href={guest.twitter}>
            <img src={twitter} />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className={twMerge('flex flex-col', className)} {...props}>
      <div className="self-center text-[14px] font-semibold sm:text-[16px]">Our Partners</div>

      <div className="mt-[40px] block overflow-hidden px-[32px]">
        <Swiper
          className="max-w-fit overflow-visible"
          spaceBetween={72}
          slidesPerView="auto"
          autoplay={{ disableOnInteraction: false }}
          modules={[Autoplay]}
        >
          {PARTNERS.map(partner => (
            <SwiperSlide key={partner.name} className="flex h-auto w-fit items-center">
              {() => renderPartner(partner)}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="mt-[60px] self-center font-worksans text-[32px] font-bold uppercase sm:text-[40px]">
        Our Speakers
      </div>

      <Container className="mt-[20px] self-center text-center sm:w-[618px]">
        Entrepreneurs, founders, project leaders and developers. All high-profiles from blockchain
        industry will accompany us during the whole campus tour.
      </Container>

      <div className="mt-[80px] block overflow-hidden sm:hidden">
        <Container>
          <Swiper className="max-w-fit overflow-visible" spaceBetween={16} slidesPerView="auto">
            {GUESTS.map((guest, index) => (
              <SwiperSlide key={index} className="w-fit">
                {() => renderGuest(guest)}
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>

      <Container className="mt-[80px] hidden grid-cols-3 justify-between gap-y-[64px] sm:grid">
        {GUESTS.map(guest => renderGuest(guest))}
      </Container>
    </div>
  );
};
