import useUrlState from '@liuqiang1357/use-url-state';
import { FC } from 'react';
import { Button } from 'components/base/Button';
import { Container } from 'components/shared/Container';
import { Page } from 'components/shared/Page';
import { parseEnum } from 'utils/parsers';
import { MyCollection } from './MyCollection';
import { NftGallery } from './NftGallery';

enum Tab {
  NftGallery = 'NftGallery',
  MyCollection = 'MyCollection',
}

export const Index: FC = () => {
  const [urlState, setUrlState] = useUrlState({
    tab: undefined,
  });

  const tab = parseEnum(Tab, urlState.tab) ?? Tab.NftGallery;

  return (
    <Page contentClassName="pt-[136px] pb-[60px] sm:py-[136px]">
      <div className={'flex flex-col items-center'}>
        <div className="text-[64px] font-light">NFTs</div>
        <Container className="mt-[20px] max-w-[544px] text-center">
          We prepared exclusive NFT for each establish campus we will visit this October. Come to
          our workshop and learn how to claim it during our special NFT claiming session!
        </Container>
        <div className="mt-[40px] flex items-center">
          <Button
            className={`h-[28px] border-2 px-[12px] ${
              tab === Tab.NftGallery ? '' : 'border-transparent'
            }`}
            type={tab === Tab.NftGallery ? 'filled' : 'text'}
            size="sm"
            color={tab === Tab.NftGallery ? 'white' : 'gray'}
            onClick={() => setUrlState({ tab: Tab.NftGallery })}
          >
            NFT Gallery
          </Button>
          <Button
            className={`ml-[12px] h-[28px] border-2 px-[12px] ${
              tab === Tab.MyCollection ? '' : 'border-transparent'
            }`}
            type={tab === Tab.MyCollection ? 'filled' : 'text'}
            size="sm"
            color={tab === Tab.MyCollection ? 'white' : 'gray'}
            onClick={() => setUrlState({ tab: Tab.MyCollection })}
          >
            My Collection
          </Button>
        </div>
      </div>

      {tab === Tab.NftGallery && <NftGallery className="mt-[80px] sm:mt-[136px]" />}
      {tab === Tab.MyCollection && <MyCollection className="mt-[80px] sm:mt-[136px]" />}
    </Page>
  );
};
