import { ComponentProps, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import arrowDown from 'assets/images/index/arrow-down.svg';
import bannerMobile from 'assets/images/index/banner-mobile.svg';
import banner from 'assets/images/index/banner.svg';
import scroll from 'assets/images/index/scroll.svg';
import { Button } from 'components/base/Button';
import { Container } from 'components/shared/Container';
import { useDispatch } from 'hooks/redux';
import { setConnectWalletVisible } from 'store/slices/uiState';
import { scrollTo } from 'utils/misc';

export const Banner: FC<ComponentProps<'div'>> = ({ className, ...props }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <div className={twMerge('relative overflow-hidden', className)} {...props}>
      <div className="flex h-[100vh] min-h-[768px] flex-col items-center justify-center sm:hidden">
        <div className="text-[14px] uppercase text-[#00E599]">Decoding Web3</div>
        <div className="mt-[12px] text-center font-worksans text-[35px] font-bold uppercase leading-tight">
          USA <br /> Campus Tour
        </div>
        <div className="mt-[20px]">October 2nd - October 26th, 2022</div>
        <img className="mt-[40px]" src={bannerMobile} />

        <Button
          className="absolute bottom-[60px] left-1/2 -translate-x-1/2"
          type="text"
          onClick={() => scrollTo('tour-map')}
        >
          <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 border-[#353945] sm:h-[64px] sm:w-[64px]">
            <img src={scroll} />
          </div>
          <div className="ml-[8px] text-[12px]">Scroll down</div>
        </Button>
      </div>

      <Container className="hidden h-[100vh] min-h-[800px] items-center justify-between sm:flex">
        <div>
          <div className="font-bold uppercase text-[#00E7A2]">Decoding Web3</div>
          <div className="mt-[12px] font-worksans text-[64px] font-bold uppercase leading-tight">
            USA <br /> Campus Tour
          </div>
          <div className="mt-[20px]">October 2nd - October 26th, 2022</div>

          <div className="mt-[40px]">
            <Button
              type="filled"
              color="green"
              onClick={() => dispatch(setConnectWalletVisible(true))}
            >
              Connect Wallet
            </Button>
            <Button className="ml-[16px]" type="outline" onClick={() => navigate('/nfts')}>
              NFTs
            </Button>
          </div>
        </div>

        <img className="-mr-[200px]" src={banner} />

        <Button
          className="absolute bottom-[100px]"
          type="image"
          onClick={() => scrollTo('tour-map')}
        >
          <div className="flex h-[64px] w-[64px] items-center justify-center rounded-full border-2 border-[#353945]">
            <img src={arrowDown} />
          </div>
        </Button>
      </Container>
    </div>
  );
};
