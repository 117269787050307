import { ComponentProps, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Footer } from './Footer';
import { Header } from './Header';

interface Props extends ComponentProps<'div'> {
  contentClassName?: string;
}

export const Page: FC<Props> = ({ className, contentClassName, children, ...props }) => {
  return (
    <div
      className={twMerge(
        'flex grow flex-col bg-[#010E29] [--header-height:100px] sm:min-w-[1440px]',
        className,
      )}
      {...props}
    >
      <Header className="shrink-0" />
      <div className={twMerge('shrink-0 grow', contentClassName)}>{children}</div>
      <Footer className="shrink-0" />
    </div>
  );
};
