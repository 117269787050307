import BigNumber from 'bignumber.js';
import {
  addMethod,
  array,
  BaseSchema,
  boolean,
  date,
  lazy,
  mixed,
  number,
  object,
  ref,
  string,
} from 'yup';
import Reference from 'yup/lib/Reference';
import { Maybe, Message } from 'yup/lib/types';
import { isValidAddress } from './convertors';
import { formatValidation } from './formatters';

export interface StringLocale {
  isN3Address?: Message;
}

const stringLocale: Required<StringLocale> = {
  isN3Address: params => formatValidation('string.isN3Address', params),
};

addMethod(string, 'isN3Address', function isN3Address(message = stringLocale.isN3Address) {
  return this.test({
    message,
    name: 'isN3Address',
    exclusive: true,
    test(value) {
      return value == undefined || isValidAddress(value);
    },
  });
});

interface BigNumberLocale {
  min?: Message<{ min: number }>;
  max?: Message<{ max: number }>;
  lessThan?: Message<{ less: number }>;
  moreThan?: Message<{ more: number }>;
  positive?: Message<{ more: number }>;
  negative?: Message<{ less: number }>;
  maxDecimals?: Message<{ maxDecimals: number }>;
  integer?: Message;
}

const bigNumberLocale: Required<BigNumberLocale> = {
  min: params => formatValidation('bigNumber.min', params),
  max: params => formatValidation('bigNumber.max', params),
  lessThan: params => formatValidation('bigNumber.lessThan', params),
  moreThan: params => formatValidation('bigNumber.moreThan', params),
  positive: params => formatValidation('bigNumber.positive', params),
  negative: params => formatValidation('bigNumber.negative', params),
  maxDecimals: params => formatValidation('bigNumber.maxDecimals', params),
  integer: params => formatValidation('bigNumber.integer', params),
};

export class BigNumberSchema extends BaseSchema {
  constructor() {
    super({ type: 'bigNumber' });

    this.withMutation(() => {
      this.transform((value: any) => {
        if (this.isType(value)) {
          return new BigNumber(value);
        }
        return value;
      });
    });
  }

  public _typeCheck(value: any): value is BigNumber {
    return value instanceof BigNumber && !value.isNaN();
  }

  public min(
    min: string | number | BigNumber | Reference<string | BigNumber>,
    message = bigNumberLocale.min,
  ): BigNumberSchema {
    return this.test({
      message,
      name: 'min',
      exclusive: true,
      params: { min },
      test(value: Maybe<BigNumber>) {
        return value == undefined || value.gte(this.resolve(min));
      },
    });
  }

  public max(
    max: string | number | BigNumber | Reference<string | BigNumber>,
    message = bigNumberLocale.max,
  ): BigNumberSchema {
    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: { max },
      test(value: Maybe<BigNumber>) {
        return value == undefined || value.lte(this.resolve(max));
      },
    });
  }

  public lessThan(
    less: string | number | BigNumber,
    message = bigNumberLocale.lessThan,
  ): BigNumberSchema {
    return this.test({
      message,
      name: 'lessThan',
      exclusive: true,
      params: { less },
      test(value: Maybe<BigNumber>) {
        return value == undefined || value.lt(this.resolve(less));
      },
    });
  }

  public moreThan(
    more: string | number | BigNumber,
    message = bigNumberLocale.moreThan,
  ): BigNumberSchema {
    return this.test({
      message,
      name: 'moreThan',
      exclusive: true,
      params: { more },
      test(value: Maybe<BigNumber>) {
        return value == undefined || value.gt(this.resolve(more));
      },
    });
  }

  public postive(message = bigNumberLocale.positive): BigNumberSchema {
    return this.moreThan(0, message);
  }

  public negative(message = bigNumberLocale.negative): BigNumberSchema {
    return this.lessThan(0, message);
  }

  public maxDecimals(
    maxDecimals: number | Reference<number>,
    message = bigNumberLocale.maxDecimals,
  ): BigNumberSchema {
    return this.test({
      message,
      name: 'maxDecimals',
      exclusive: true,
      params: { maxDecimals },
      test(value: Maybe<BigNumber>) {
        return value == undefined || (value.dp() ?? NaN) <= maxDecimals;
      },
    });
  }

  public integer(message = bigNumberLocale.integer): BigNumberSchema {
    return this.maxDecimals(0, message);
  }
}

export const validators = {
  mixed,
  boolean,
  string,
  number,
  date,
  object,
  array,
  ref,
  lazy,
  bigNumber: (): BigNumberSchema => new BigNumberSchema(),
};
