import { createApi } from '@reduxjs/toolkit/query/react';
import { NFT_CONTRACT_HASH } from 'utils/configs';
import { addressToScriptHash } from 'utils/convertors';
import { invokeReadBaseQuery } from 'utils/queries';

export const invokeReadApi = createApi({
  reducerPath: 'invokeReadApi',
  baseQuery: invokeReadBaseQuery(),
  tagTypes: ['MintedCount', 'Minted'],
  endpoints: builder => ({
    getMintedCount: builder.query<number, { nftCategoryName: string }>({
      query: ({ nftCategoryName }) => ({
        scriptHash: NFT_CONTRACT_HASH,
        operation: 'totalMint',
        args: [{ type: 'String', value: nftCategoryName }],
      }),
      transformResponse: (result: any) => Number(result[0].value),
      providesTags: ['MintedCount'],
    }),

    getMinted: builder.query<boolean, { address: string; secret: string }>({
      query: ({ address, secret }) => ({
        scriptHash: NFT_CONTRACT_HASH,
        operation: 'hasMint',
        args: [
          { type: 'Hash160', value: addressToScriptHash(address) },
          { type: 'String', value: secret },
        ],
      }),
      transformResponse: (result: any) => result[0].value,
      providesTags: ['Minted'],
    }),
  }),
});

export const { useGetMintedCountQuery, useGetMintedQuery } = invokeReadApi;
