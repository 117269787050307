import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk as createReduxAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { mapValuesDeep } from 'deepdash-es/standalone';
import { $enum } from 'ts-enum-util';
import { Dispatch, State } from 'store';
import { theme } from '../../tailwind.config';
import { CURRENT_STATION, STATIONS } from './configs';

export { theme };

export const BREAKPOINTS = { base: '0px', ...theme?.screens };

// eslint-disable-next-line no-restricted-syntax
export const DEPRECATED_NULL = null;

export function mapUndefinedToNull(data: any): any {
  return mapValuesDeep(data, value => value ?? DEPRECATED_NULL, { leavesOnly: true });
}

export function mapNullToUndefined(data: any): any {
  return mapValuesDeep(data, value => value ?? undefined, { leavesOnly: true });
}

export function mapEnumToObject<E extends string | number, R>(
  e: Record<string, E>,
  iterator: (value: E) => R,
): Record<E, R> {
  return $enum(e)
    .getValues()
    .reduce((acc, cur) => {
      acc[cur] = iterator(cur);
      return acc;
    }, {} as Record<E, R>);
}

export function createAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    { state: State; dispatch: Dispatch }
  >,
  options?: AsyncThunkOptions<ThunkArg, Record<string, any>>,
): AsyncThunk<Returned, ThunkArg, Record<string, any>> {
  return createReduxAsyncThunk(typePrefix, payloadCreator, {
    serializeError: (error: unknown): SerializedError => error as SerializedError,
    ...options,
  });
}

export function scrollTo(id: string): void {
  const el = document.querySelector<HTMLElement>(`#${id}`);
  if (el) {
    document.scrollingElement?.scrollTo({
      top: el.offsetTop,
      behavior: 'smooth',
    });
  }
}

export const CURRENT_STATION_INDEX = STATIONS.findIndex(
  station => station.name === CURRENT_STATION,
);
