const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    colors: {
      current: 'currentColor',
      transparent: 'transparent',
      black: colors.black,
      white: colors.white,
    },
    fontFamily: {
      sans: ['Poppins', ...defaultTheme.fontFamily.sans],
      worksans: ['Work Sans', ...defaultTheme.fontFamily.sans],
      mono: defaultTheme.fontFamily.mono,
    },
    screens: {
      sm: '640px',
    },
    extend: {
      boxShadow: {
        '3xl': [
          '0 3px 6px -4px rgba(0, 0, 0, 0.48)',
          '0 6px 16px 0 rgba(0, 0, 0, 0.32)',
          '0 9px 28px 8px rgba(0, 0, 0, 0.2)',
        ],
      },
    },
  },
  corePlugins: {
    preflight: false,
  },
};
